import React from "react";
import Layout from "../../components/Layout/Layout";
import { useStaticQuery, graphql, navigate, Link } from "gatsby";
import TinTuc from "../../components/TinTucMoiNhat/TinTuc";
import { Box, Button, useMediaQuery } from "@material-ui/core";
import { useStyles } from "../../index.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { truncateString } from "../../helpers/helpers";
import dayjs from "dayjs";
import vi from "dayjs/locale/vi";

const DetailsPost = ({ pageContext: { postId } }) => {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");
  const breakpoints = useMediaQuery("(max-width: 765px)");

  const listPost = useStaticQuery(
    graphql`
      query getAllPosts($postId: String) {
        allWpPost(
          sort: { fields: date, order: DESC }
          filter: { id: { eq: $postId } }
        ) {
          edges {
            node {
              content
              id
              title
              date
              excerpt
              featuredImage {
                node {
                  localFile {
                    url
                  }
                  altText
                }
              }
            }
          }
          totalCount
        }
      }
    `
  );

  const post = listPost?.allWpPost?.edges?.find(x => x.node.id === postId);

  const morePost = () => {
    const more = listPost?.allWpPost?.edges?.filter(x => x.node.id !== postId);
    if (more) {
      return more;
    } else {
      return [];
    }
  };

  if (!post) {
    navigate("/");
  }

  return (
    <Layout>
      <Box
        width={breakpointsXs ? "95%" : "80%"}
        margin="auto"
        my={breakpointsXs ? 4 : 8}
      >
        {post && (
          <>
            <Box
              mb={4}
              fontSize={18}
              fontWeight={700}
              display="flex"
              flexWrap="wrap"
              color="#551a8b"
            >
              <Link style={{ color: "#308632" }} to="/">
                Trang chủ
              </Link>
              &nbsp;<Box style={{ color: "#308632" }}>/</Box>&nbsp;
              <Link to="/tin-tuc" style={{ color: "#308632" }}>Tin tức </Link>
              &nbsp;<Box style={{ color: "#308632" }}>/</Box>&nbsp;
              <Box color="black">{truncateString(post.node.title)}</Box>
            </Box>
            <Box fontSize={14} color="#308632" mb={2}>
              Ngày đăng:{" "}
              {dayjs(post.node.date).locale(vi).format("DD/MM/YYYY Lúc HH:mm")}
            </Box>
            <Box
              display={!breakpoints ? "flex" : undefined}
              justifyContent="space-between"
              alignItems="center"
              py={2}
            >
              <Box
                mb={2}
                width={breakpoints ? "100%" : "20%"}
                height={breakpoints ? undefined : 150}
                className={breakpoints ? classes.heighImage : undefined}
              >
                <img
                  style={{ width: 150, height: 150 }}
                  src={post.node.featuredImage?.node.localFile.url}
                  alt=""
                />
              </Box>
              <Box fontSize={25} width={breakpoints ? "100%" : "78%"}>
                <b>{post.node.title}</b>
              </Box>
            </Box>

            <Box
              className={classes.contentPost}
              dangerouslySetInnerHTML={{
                __html: post.node.content,
              }}
            />
          </>
        )}
        {morePost().length !== 0 && (
          <>
            <hr />
            <Box mt={8} color="#308632" fontSize={30} fontWeight={700}>
              Tin tức khác
            </Box>
            <Box
              mt={4}
              display={!breakpointsXs ? "flex" : undefined}
              flexWrap="wrap"
              style={{ columnGap: 12 }}
            >
              {morePost().map(item => (
                <>
                  <Box width={breakpointsXs ? "100%" : "32%"} mb={5}>
                    <Box
                      overflow="hidden"
                      className={classes.heighImage}
                      bgcolor="#e3edf3"
                      mb={2}
                    >
                      <img
                        className="image"
                        src={item.node.featuredImage?.node?.localFile.url}
                        alt=""
                      />
                    </Box>
                    <TinTuc
                      date={item.node.date}
                      title={item.node.title}
                      content={item.node.excerpt}
                    />
                    <Link to={`/tin-tuc/${item.node.id}`}>
                      <Button className={classes.buttonViewDetail}>
                        <b style={{ color: "white" }}>XEM CHI TIẾT</b>
                        <Box ml={1.5}>
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Box>
                      </Button>
                    </Link>
                  </Box>
                </>
              ))}
            </Box>
            <hr />
          </>
        )}
      </Box>
    </Layout>
  );
};

export default DetailsPost;
