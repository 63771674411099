import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as dayjs from "dayjs";
import vi from "dayjs/locale/vi";

const useStyles = makeStyles(() => ({
  description: {
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "break-spaces",
  },
}));

const TinTuc = ({ date, title, content }) => {
  return (
    <Box mb={2} minHeight={100} textOverflow="ellipsis" overflow="hidden">
      <Box fontSize={16} color="#308632">
        {dayjs(date).locale(vi).format("Ngày đăng: DD/MM/YYYY Lúc HH:mm")}
      </Box>
      <Box className={useStyles().description} fontSize={20} my={1}>
        <b>{title}</b>
      </Box>

      <Box
        fontSize={16}
        className={useStyles().description}
        color="grey"
        display="-webkit-box"
        lineClamp={2}
        overflow="hidden"
        textOverflow="ellipsis"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Box>
  );
};

export default TinTuc;
